<template>
  <v-card class="m-reset-password pa-2">
    <v-row no-gutters justify="center" class="pt-3">
      <a :href="$store.state.siteUrl" class="m-auth__logo-container">
        <img
          :src="require('@/assets/ielts/ielts-logo.svg')"
          height="70"
          alt="Fluent Logo"
        />
      </a>
    </v-row>
    <v-card-text>
      <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-row no-gutters justify="center">
          <h4 class="text-md-center primary--text mb-2">Reset Password</h4>
        </v-row>
        <v-form v-model="formOk">
          <v-container fluid class="pl-lg-3 pl-sm-0 pr-lg-3 pl-sm-0">
            <v-row no-gutters wrap>
              <v-col md="12" sm="12" class="pl-0 pr-0">
                <v-text-field
                  v-model="form.password"
                  :prepend-icon="'lock'"
                  :append-outer-icon="
                    newPasswordVisible ? 'visibility_off' : 'visibility'
                  "
                  :type="newPasswordVisible ? 'text' : 'password'"
                  browser-autocomplete="new-password"
                  name="password"
                  label="New Password"
                  :rules="[passwordValidation]"
                  @click:append-outer="newPasswordVisible = !newPasswordVisible"
                ></v-text-field>
              </v-col>
              <v-col class="pl-0 pr-0" md="12" sm="12">
                <v-text-field
                  v-model="confirmPassword"
                  :prepend-icon="'lock'"
                  :append-outer-icon="
                    confirmPasswordVisible ? 'visibility_off' : 'visibility'
                  "
                  :type="confirmPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Confirm Password"
                  @keypress.native.enter="reset"
                  :rules="[v=>required(v, 'confirmation password'), v=>passwordMatch(v, form.password)]"
                  @click:append-outer="
                    confirmPasswordVisible = !confirmPasswordVisible
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-row no-gutters justify="space-between" align-center>
            <v-spacer></v-spacer>
            <v-btn
              @click="reset"
              :loading="isLoading"
              depressed
              color="primary"
              :disabled="!formOk"
              >Reset Password</v-btn
            >
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import authService from "../services/AuthService";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "MResetPassword",
  props: {
    token: String
  },
  mixins: [FieldValidations],
  data() {
    return {
      form: {
        password: ""
      },
      formOk: false,
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      isLoading: false,
      confirmPassword: "",
    };
  },
  methods: {
    async reset() {
      this.isLoading = true;
      try {
        await authService.resetPassword(this.token, this.form.password);
        this.$router.push("/auth/signin");
        this.$store.commit("showInfo", "Your password has been reset.");
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Password reset failed. Reset URL may be expired"
        ]);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.m-reset-password {
  max-width: 500px;
  border: 1px solid #dadce0 !important;
  border-radius: 8px;
  box-shadow: none !important;
  min-height: 350px;

  @media (max-width: 600px) {
    min-width: 100vw;
    height: 100vh;
  }
  .v-card-title {
    padding-bottom: 5px;
  }
  .v-input--checkbox {
    .v-label {
      margin-bottom: 0;
    }
  }
}
</style>
