// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".m-reset-password {\n  max-width: 500px;\n  border: 1px solid #dadce0 !important;\n  border-radius: 8px;\n  box-shadow: none !important;\n  min-height: 350px;\n}\n@media (max-width: 600px) {\n.m-reset-password {\n    min-width: 100vw;\n    height: 100vh;\n}\n}\n.m-reset-password .v-card-title {\n  padding-bottom: 5px;\n}\n.m-reset-password .v-input--checkbox .v-label {\n  margin-bottom: 0;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
