var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-reset-password pa-2" },
    [
      _c(
        "v-row",
        { staticClass: "pt-3", attrs: { "no-gutters": "", justify: "center" } },
        [
          _c(
            "a",
            {
              staticClass: "m-auth__logo-container",
              attrs: { href: _vm.$store.state.siteUrl },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/ielts/ielts-logo.svg"),
                  height: "70",
                  alt: "Fluent Logo",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { class: { "pa-0": _vm.$vuetify.breakpoint.smAndDown } },
            [
              _c("v-row", { attrs: { "no-gutters": "", justify: "center" } }, [
                _c("h4", { staticClass: "text-md-center primary--text mb-2" }, [
                  _vm._v("Reset Password"),
                ]),
              ]),
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.formOk,
                    callback: function ($$v) {
                      _vm.formOk = $$v
                    },
                    expression: "formOk",
                  },
                },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pl-lg-3 pl-sm-0 pr-lg-3 pl-sm-0",
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", wrap: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pr-0",
                              attrs: { md: "12", sm: "12" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "lock",
                                  "append-outer-icon": _vm.newPasswordVisible
                                    ? "visibility_off"
                                    : "visibility",
                                  type: _vm.newPasswordVisible
                                    ? "text"
                                    : "password",
                                  "browser-autocomplete": "new-password",
                                  name: "password",
                                  label: "New Password",
                                  rules: [_vm.passwordValidation],
                                },
                                on: {
                                  "click:append-outer": function ($event) {
                                    _vm.newPasswordVisible =
                                      !_vm.newPasswordVisible
                                  },
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pr-0",
                              attrs: { md: "12", sm: "12" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "lock",
                                  "append-outer-icon":
                                    _vm.confirmPasswordVisible
                                      ? "visibility_off"
                                      : "visibility",
                                  type: _vm.confirmPasswordVisible
                                    ? "text"
                                    : "password",
                                  name: "password",
                                  label: "Confirm Password",
                                  rules: [
                                    (v) =>
                                      _vm.required(v, "confirmation password"),
                                    (v) =>
                                      _vm.passwordMatch(v, _vm.form.password),
                                  ],
                                },
                                on: {
                                  "click:append-outer": function ($event) {
                                    _vm.confirmPasswordVisible =
                                      !_vm.confirmPasswordVisible
                                  },
                                },
                                nativeOn: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.reset.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.confirmPassword = $$v
                                  },
                                  expression: "confirmPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        justify: "space-between",
                        "align-center": "",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.isLoading,
                            depressed: "",
                            color: "primary",
                            disabled: !_vm.formOk,
                          },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("Reset Password")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }